/* eslint-disable import/no-extraneous-dependencies */
import { ItemModel } from '@leverege/ui-attributes'
import React from 'react'
import { PuffLoader as Loader } from 'react-spinners'
import { GlobalState } from '@leverege/ui-redux'
import Theme from './theme'
import LimitToRolesMutator from './mutators/LimitToRolesMutator'
import ActionPersonaMutator from './mutators/ActionPeronsaMutator'

const strToBool = str => str && str !== 'false' && str !== 'FALSE' && str !== 'False'

const adminHandles = ( context ) => {
  const hasDefault = GlobalState.get()?.ui?.activePersona.name === 'shipConfig'
  return hasDefault
}

function create() {

  return {
    uiName : process.env.UI_NAME,
    baseUrl : null,
    personaUrl : '/p/:persona',
    molten : {
      splashClass : Loading,
    },
    theme : {
      theme : Theme,
      appearanceProjectId : process.env.APPEARANCE_PROJECT_ID || '3A48uiQ626esPO2PcnwmaN',
      useActiveTheme : strToBool( process.env.IS_DEV )
    },
    maps : {
      apiKey : process.env.GOOGLE_MAPS_API_KEY,
      mapId : process.env.GOOGLE_MAPS_MAP_ID
    },
    api : {
      host : process.env.IMAGINE_HOST || 'http://localhost:8181',
      systemId : process.env.SYSTEM_ID,
      projectId : process.env.PROJECT_ID,
      storeToken : true
    },
    googleAnalytics : { 
      trackerId : process.env.GOOGLE_ANALYTICS_TRACKER_ID
    },
    DetailTypes : [
      {
        model : ItemModel,
        settingsPath : 'associationModel',
        actionName : 'Association',
        routePath : '/settings/association',
        icon : 'fa fa-plug fa-fw'
      } 
    ],
    plugins : {
      // LoadConfig will use these and call Config.set()
      config : {
        'molten.dataViewers.layout.RenameLayoutAction' : {
          handles : adminHandles
        },
        'molten.dataViewers.layout.DeleteLayoutAction' : {
          handles : adminHandles
        },
        'molten.dataViewers.layout.SetLayoutAsDefaultAction' : {
          handles : adminHandles
        },
        GroupScreen : {
          defaultPerPage : 200
        },
        BlueprintRoutes : {
          baseUrl : '/p/:persona'
        },
        ItemScreen : {
          collapsable : true, 
          collapseLabel : 'Collapse',
          collapseOpenIcon : 'fa fa-chevron-right',
          collapseCloseIcon : 'fa fa-chevron-left'
        },
        UserSettings : {
          // delegate : <delegate class>
        },
        ResponsiveDataViewer : {
          minWidth : 430
        },
        ResponsiveUtility : {
          smallBreakpoint : 555
        },
        Main : {
          // userIcon : 'fa fa-star fa-fh'
        },
        LoginScreen : {
          oidcClientFilter : [ process.env.OIDC_CLIENT_FILTER ]
          // userText : 'Account Email'
        },
        AccountScreen : {
          redirectUrl : '/account/profile'
        },
        LoadingScreen : {
          spinner : Loader,
          props : { size : 70 } // error in lib -this one is off center
        },
        LoadingView : {
          spinner : Loader,
          props : { size : 30 } // error in lib -this one is off center
        },
        LostScreen : {
          image : [
            'https://storage.googleapis.com/molten-ui-assets/404-UFO.png',
            'https://storage.googleapis.com/molten-ui-assets/404-Earth.png',
            'https://storage.googleapis.com/molten-ui-assets/404-Page.png',
            'https://storage.googleapis.com/molten-ui-assets/404-Ghost.png' ]
        },
        LayoutViewerSelector : {
          allowEditing : 'shipConfig' // 'Installer, UserManager, default'
        },

        mapBoxPosition : {
          center : [ -77.1902656, 39.1081595 ],
          zoom : 18,
          selectedRezoomThreshold : 12, // if we re beyond this range, selection will cause rezoom/center
          selectedZoom : 18, // this is the target range to go to on selection. 
          pathOptions : {
            building : { selectedRezoomThreshold : 10, selectedZoom : 18 }
          }
        }
      },
      excludes : [
        // turn off simple search analysis
        // 'molten.BlueprintRouteCreator.SimpleSearch'
      ],
      mutators : [
        ActionPersonaMutator,
        LimitToRolesMutator,
      ]
    },
    actions : {
      'molten.dataViewers.list.EditSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.table.EditSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.screens.group.search.SearchSettings' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.details.EditDetailsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.map.EditSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.history.EditSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.aggregations.ContainerEditSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.aggregations.EditSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.layout.DefaultLayoutAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.layout.SaveAsLayoutAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.layout.ResetLayoutAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.dataViewers.layout.SaveLayoutAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'list.SettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'table.SettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'search.EditSearchSettingsActionMobile' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'search.EditSearchSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.mapDataViewer.EditSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'link.desktop.Settings' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'link.mobile.Settings' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'dataCard.SettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'dashboard.ToggleEditingAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'aggregations.SettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'layout.AdvancedSettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'molten.layout.EditLayoutAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'history.SettingsAction' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      },
      'blueprint.action.firmware.CreateItem' : {
        limitToRoles : [ 'Admin', 'shipConfig' ]
      }
    }
  }
}

function Loading( props ) {
  return React.createElement( 'div', null, 'Loading SirenMarineShipUiMolten...' )
}

export default { create }
