/* eslint-disable import/no-import-module-exports */
// import plugins from libraries here
import BoatPlugins from './boat/PluginSetup'
import FirmwarePlugins from './firmware/PluginSetup'
import GlobalStarPlugins from './globalstar/PluginSetup'
import WirelessPlugins from './wireless/PluginSetup'
import FirmwareVersionPLugins from './firmwareVersion/PluginSetup'
import CustomersPlugins from './customers/PluginSetup'
import DailyReportsPlugins from './dailyReports/PluginSetup'

exports.install = ( molten ) => {
  BoatPlugins.install( molten )
  WirelessPlugins.install( molten )
  GlobalStarPlugins.install( molten )
  FirmwareVersionPLugins.install( molten )
  FirmwarePlugins.install( molten )
  if ( process.env.HAS_CUSTOMERS_PAGE === 'true' ) {
    CustomersPlugins.install( molten )
  }
  if ( process.env.HAS_DAILY_REPORTS_PAGE === 'true' ) {
    DailyReportsPlugins.install( molten )
  }
}
