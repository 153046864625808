import ESNAttribute from './attributes/ESNAttribute'
import ESNHiddenDataViewer from './dataViewers/ESNHiddenDataViewer'
import ESNAttributeRenderer from './renderes/ESNAttributeRenderer'

const install = ( molten ) => {
  molten.addPlugin( 'Attribute', ESNAttribute ) // Empty - To be rendered by the renderer
  molten.addPlugin( 'AttributeCellRenderer', ESNAttributeRenderer )
  molten.addPlugin( 'DataViewer', ESNHiddenDataViewer )
}

export default {
  install,
}
