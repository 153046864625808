import { Flex, Toast } from '@leverege/ui-elements'
import React from 'react'
import { useSelector } from 'react-redux'

export default function BoatsFleets() {
  const boatFleets = useSelector( state => state?.ui?.boatFleets )

  if ( !boatFleets ) return <i className="fa-solid fa-spinner-third fa-spin" />

  const onClick = ( v ) => {
    navigator.clipboard.writeText( v )

    Toast.success( 'Fleet Id copied to clipboard' )
  }

  const content = boatFleets.map( b => ( {
    content : b?.fleetName,
    fleetId : b?.fleetId
  } ) )

  return (
    <Flex direction="column" >
      {
        content.length ? content.map( v => (
          <button 
            key={v.fleetId}
            style={{ justifyContent : 'flex-end' }}
            className="lev-button lev-sc-button-link lev-sc-button-linkTable"
            type="button"
            onClick={() => onClick( v.fleetId )}>
            {v.content}
          </button>
        ) ) : '-'
      }
    </Flex>
  )
}
