import moment from 'moment'

const formatFileName = ( data ) => {

  const prefixes = {
    wireless : 'Wireless Sensor Database',
    globalstar : 'Globalstar Database',
    customer : 'Customer Database',
  };

  const prefixKey = Object.keys( prefixes ).find( key => data?.path?.includes( key ) );
  const prefix = prefixes[prefixKey]
  
  const date = moment( data?.createdAt ).format( 'MM/DD/YYYY' );
  return `${prefix} ${date}`;
};

module.exports = { formatFileName }
