/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'

import { RoutesUtil as Util } from '@leverege/molten/routes'
import { GlobalState } from '@leverege/ui-redux'
import { push } from 'connected-react-router'

export default {
  objectType : 'boat',
  attrName : 'Parent Device ESN',
  renderer : ( props ) => {
    const { context : { clientProps : { match, dispatch = GlobalState.dispatch } } } = props 
    const path = '/p/:persona/boat/:boatId/info' 
    const esn = props?.row?.original?.data?.networkAliases?.['siren-marine']?.esn
    const id = props?.row?.original?.data?.id

    const newPath = match ? Util.resolve( path, { ...match, params : { ...match?.params, boatId : id } } ) : ''

    if ( !newPath ) return esn

    return (
      <button 
        className="lev-button lev-sc-button-link lev-sc-button-linkTable"
        type="button"
        onClick={() => dispatch( push( newPath ) )}>
        <div 
          className="lev-text lev-sc-text-linkTable"
          style={{ whiteSpace : 'nowrap', color : 'var( --color-columnValue, currentColor )s' }}>
          {esn}
        </div>
      </button> 
    )
  }
} 
