/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { RoutesUtil as Util } from '@leverege/molten/routes'
import { DataSources } from '@leverege/ui-attributes'
import { Breadcrumbs, Button, Content, Flex, TextArea, Toast, Pane } from '@leverege/ui-elements'
import { TitleBar, Toolbar } from '@leverege/ui-plugin'
import { GlobalState, } from '@leverege/ui-redux'
import { push } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ConfirmationDialog from '../../components/ConfirmationDialog'

export default function BoatCommentsCard( props ) {
  const { parentItem, match } = props
  const { id, type, data : { systemId } } = parentItem
  const { notes : initialNotes } = parentItem?.data?.data || {}
  const networkName = parentItem?.data?.networkId || null
  const name = parentItem?.data?.networkAliases?.[networkName]?.esn || parentItem.data.name
  const [ notes, setNotes ] = useState( initialNotes ?
    initialNotes.split( '\n' ).map( content => ( { id : Date.now() + Math.random(), content } ) ) : [] )
  const [ input, setInput ] = useState( '' )
  const [ hasChanged, setHasChanged ] = useState( false )
  const [ loading, setLoading ] = useState( false )
  const [ editingId, setEditingId ] = useState( null )
  const [ hoveredNote, setHoveredNote ] = useState( null )
  const [ editingDate, setEditingDate ] = useState( null )
  const [ noteDeleteId, setNoteDeleteId ] = useState( false )

  const handleCancel = () => {
    setInput( '' )
    setEditingId( null )
  }

  const rootPath = match ? Util.resolve( '/p/:persona/boat', match ) : ''
  const overViewPath = match ? Util.resolve( '/p/:persona/boat/:boatId/info', match ) : ''

  const onClickBreadcrumb = ( ctx ) => {
    const isRoot = ctx.data?.root
    GlobalState.dispatch( isRoot ? push( rootPath ) : push( overViewPath ) )
  }

  const user = useSelector( ( state ) => {
    return state.auth.profile.username 
  } )
  const getDate = () => {
    const now = new Date()
    const dateOptions = { year : 'numeric', month : '2-digit', day : '2-digit' }
    const timeOptions = { hour : '2-digit', minute : '2-digit' }
    const dateString = now.toLocaleDateString( 'en-US', dateOptions )
    const timeString = now.toLocaleTimeString( 'en-US', timeOptions )
    const dateTimeString = `${dateString} ${timeString}`
    return dateTimeString
  }
  
  const delegate = DataSources.getDelegate( { id, ref : `${systemId}/boat`, type } )
  
  const handleSave = async () => {
    setLoading( true );
    const updatedNotes = [ ...notes ];

    const dateForNote = editingDate || getDate()
    const editedTag = editingId ? ' Edited' : ''

    const newNoteContent = `${user} ${dateForNote} - ${input}${editedTag}`;
    if ( editingId ) {
      const noteIndex = updatedNotes.findIndex( n => n.id === editingId );
      updatedNotes[noteIndex].content = newNoteContent;
      setEditingId( null ); // Reset the editing state
      setEditingDate( null )
    } else if ( user ) {
      updatedNotes.push( {
        id : Date.now(),
        content : newNoteContent
      } );
    }

    const notesString = updatedNotes.map( noteObj => noteObj.content ).join( '\n' );

    await GlobalState.dispatch( async ( dispatch ) => {
      try {
        await dispatch( delegate.update( { data : { notes : notesString } } ) )
        setNotes( updatedNotes )
        setInput( '' )
        Toast.success( 'Notes were saved successfully!' )
      } catch ( err ) {
        Toast.error( 'Something went wrong. Try again later.' )
      }
    } )
    setLoading( false )
    setHasChanged( false )
  }

  const handleDelete = async ( id ) => {
    setLoading( true );
    const updatedNotes = [ ...notes ];

    const filteredNotes = updatedNotes.filter( n => n.id !== id )

    const notesString = filteredNotes.map( noteObj => noteObj.content ).join( '\n' );

    await GlobalState.dispatch( async ( dispatch ) => {
      try {
        await dispatch( delegate.update( { data : { notes : notesString } } ) )
        setNotes( filteredNotes )
        setInput( '' )
        Toast.success( 'Notes were saved successfully!' )
      } catch ( err ) {
        Toast.error( 'Something went wrong. Try again later.' )
      }
    } )
    setLoading( false )
    setHasChanged( false )
  }

  const extractUserFromNote = ( content ) => {
    const regex = /^([^\d]+)\d+/;
    const match = content.match( regex );
    return match ? match[1].trim() : null;
  }

  const handleEdit = ( id ) => {
    const noteToEdit = notes.find( n => n.id === id );
    if ( noteToEdit ) {
      const parts = noteToEdit.content.split( '-' )
      const date = parts[0].replace( user, '' ).trim()
      setEditingDate( date )
      
      const actualNoteContent = parts.slice( 1 ).join( '-' ).replace( 'Edited', '' ).trim();
      setInput( actualNoteContent );
    }
    setEditingId( id ); // Set the ID of the note being edited
  }

  useEffect( () => {
    setHasChanged( input !== initialNotes )
  }, [ input, initialNotes ] )

  useEffect( () => {
    setNotes( 
      initialNotes ? initialNotes
        .split( '\n' )
        .map( content => ( { id : Date.now() + Math.random(), content } ) ) : 
        [] )
  }, [ initialNotes ] )

  return (
    <>
      <style>{`
          .actions {
            display: flex;
            margin-left: 10px;
            gap: 10px;
          }

          .edited-tag {
            font-size: 0.8em;
            color: #666;
            margin-left: 5px;
            font-style: italic;
          }
        `}
      </style>
      <Content>
        <Pane variant="topNavBreadcrumbs">
          <Breadcrumbs 
            variant="topNavBreadcrumbs"
            onClick={onClickBreadcrumb}
            crumbs={[ 
              { name : process.env.BOAT_BREADCRUMB_NAME, root : true },
              { name, device : true },
              { name : 'Comments' }
            ]}
            className="lev-pane lev-sc-pane-mainScreenHeader"/>
        </Pane>
        <Content.Header>
          <TitleBar
            variant="screenTitle"
            title="Comments"
            icon="fa-solid fa-book-user">
            <Toolbar
              variant="actionBar"
              prefer="icon"
              matchContext={{}}
              context={props} />
          </TitleBar>
        </Content.Header>
        <Content.Area>
          <Flex 
            direction="column"
            style={{ padding : '0 16px', width : '100%' }} >
            {notes.slice().reverse().map( ( noteObj ) => {
              const noteUser = extractUserFromNote( noteObj.content );
              const actualNoteContent = noteObj.content.split( '-' )[1]?.trim();
              const isEdited = noteObj.content.includes( 'Edited' )
              const displayContent = isEdited ? noteObj.content.replace( ' Edited', '' ) : noteObj.content
              return (
                <div
                  key={noteObj.id}
                  className="note-div"
                  style={{ marginTop : 5, marginBottom : 5, display : 'flex', alignItems : 'center' }}
                  onMouseEnter={() => {
                    setHoveredNote( noteObj.id ) 
                  }}
                  onMouseLeave={() => setHoveredNote( null )}>
                  {editingId === noteObj.id ? (
                    <div style={
                      { width : '100%', display : 'flex', alignItems : 'center', justifyContent : 'space-between' }
                    }>
                      <TextArea
                        value={input}
                        onChange={txt => setInput( txt.value )}
                        style={{ width : '80%' }}/>
                      <Button variant="primary"
                        disabled={!hasChanged || !input.length}
                        onClick={handleSave}
                        style={{
                          marginRight : '15px',
                          marginLeft : '15px',
                          width :
                    '15%'
                        }}>Save
                      </Button>
                      <Button variant="secondary"
                        onClick={handleCancel}
                        style={{
                          marginRight : '15px', width : '15%'
                        }}>Cancel
                      </Button>
                    </div>
                  ) : (
                    <>
                      <p style={{ marginTop : '3px', marginBottom : '3px' }}>
                        {displayContent}
                        {isEdited && <span className="edited-tag">Edited</span>}
                      </p>
                      {hoveredNote === noteObj.id && user === noteUser && (
                        <div className="actions">
                          <button
                            type="button"
                            onClick={() => {
                              setInput( actualNoteContent );
                              handleEdit( noteObj.id );
                            }}
                            style={{
                              paddingTop : '5px',
                              paddingBottom : '5px',
                            }}>
                            <i className="fa-solid fa-pen-to-square" />
                          </button>
                          <button
                            type="button"
                            onClick={() => setNoteDeleteId( noteObj.id )}
                            style={{
                              paddingTop : '5px',
                              paddingBottom : '5px',
                            }}>
                            <i className="fa-solid fa-trash" />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            } )}
            <ConfirmationDialog 
              show={!!noteDeleteId}
              headerText="Delete confirmation"
              bodyText="Are you sure you want to delete this note?"
              onDeny={() => setNoteDeleteId( null )}
              onConfirm={() => {
                handleDelete( noteDeleteId ) 
                setNoteDeleteId( null ) 
              }}/>
            <Flex variant="tableToolbar" style={{ padding : '0 0 8px 0 ' }}>
              <Flex variant="colM" style={{ width : '100%' }}>
                <TextArea
                  disabled={editingId}
                  style={{ height : 240, marginTop : 10 }}
                  placeholder="Write New Note..."
                  value={input}
                  onChange={txt => setInput( txt.value )} />
              </Flex>
            </Flex>
            <Flex variant="tableToolbar" justify="flex-end" style={{ paddingBottom : 16 }}>
              <Flex variant="rowL">
                <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                <Button
                  icon={loading ? 'fa fa-circle-notch fa-spin' : null}
                  variant="primary"
                  disabled={!hasChanged || !input.length}
                  onClick={handleSave}>
                  Save
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Content.Area>
      </Content>
    </>
  )
}
