// eslint-disable-next-line import/no-extraneous-dependencies
import { GlobalState } from '@leverege/ui-redux'
import { Blueprints } from '@leverege/api-attributes'

const formatTimeStamp = ( timestamp ) => {
  if ( timestamp == null ) return 'Invalid Date'
  const timeStamp = new Date( timestamp )
  if ( Number.isNaN( timeStamp.getTime() ) ) return 'Invalid Date'
  return new Intl.DateTimeFormat( 'en-US', {
    year : 'numeric',
    month : '2-digit',
    day : '2-digit',
    hour : '2-digit',
    minute : '2-digit',
    hour12 : false,
    timeZoneName : 'short'
  } ).format( timeStamp )
}

export async function getParentBoats( systemId, boatIds ) {
  const { items } = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.interface( systemId, 'boat' ).search( {
        filter : {
          type : 'equals',
          field : 'id',
          value : boatIds,
        },
      } )
    },
  )

  return {
    ...items?.reduce(
      ( acc, boat ) => ( {
        ...acc,
        [boat?.id] : boat?.networkAliases?.['siren-marine']?.esn ?? '-',
      } ),
      {},
    ),
  }
}

export async function setBoatAlertRecipients( boatId, newRecipients ) {
  await GlobalState.dispatch( async ( dispatch, getState, { api } ) => {
    return api.device( boatId ).setValue( 'alertRecipients', newRecipients )
  } )
}

export async function setBoatFirmwareToBoat( boatESN, firmwareId ) {
  await GlobalState.dispatch( async ( dispatch, getState, { api } ) => {
    const boat = await api.getDeviceByNetworkAlias( 'siren-marine', 'esn', boatESN )
    const { id } = boat

    await api.device( id ).message( { path : 'vessel/firmware', value : { firmwareId } } )
  } )
}

// Boat/Customers page api calls
export async function getDevicesWithBoatId( systemId, boatId, deviceAlias ) {
  const { items } = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.interface( systemId, deviceAlias ).obj( '*' ).grp( 'boat' ).obj( boatId )
        .get()
    },
  )

  const devices = items?.map( fleetGroup => ( {
    ref : fleetGroup.ref,
    ...fleetGroup,
  } ) )

  return devices
}

export async function getUsersFromDevice( deviceId ) {
  const { items } = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.device( deviceId ).users().list()
    }
  )

  const users = items?.map( user => ( {
    ref : user.ref,
    ...user,
  } ) )

  return users
}

// list devices under a user
export async function getDevicesUnderUser( userId ) {
  const { items } = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.user( userId ).devices()
    }
  )

  const devices = items?.map( device => ( {
    ref : device.ref,
    ...device
  } ) )

  return devices
}

export async function getRolesOnDeviceFromUser( projectId, userId ) {
  const { items } = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.project( projectId ).account( userId ).roles()
    }
  )

  const devices = items?.map( device => ( {
    ref : device.ref,
    ...device
  } ) )

  return devices
}

export async function getConsolidatedDeviceData( userId, projectId ) {
  try {
    const devices = await getDevicesUnderUser( userId )
    const rolesData = await getRolesOnDeviceFromUser( projectId, userId )

    const transformedData = await Promise.all( devices.map( async ( device ) => {

      const blueprintData = await Blueprints.getByType( device.blueprintId )
      const blueprintType = blueprintData.type || 'Unknown'

      const deviceRoles = rolesData.filter( role => role.resourceId === device.id )
      const rolesList = deviceRoles.length > 0 ?
        deviceRoles.map( role => role?.role.displayName || role?.role.name ) :
        [ 'No role found' ]

      return {
        ...device,
        blueprintType,
        roles : rolesList,
        created : formatTimeStamp( device.created ),
        lastModified : formatTimeStamp( device.lastModified ),
        id : device.id
      }
    } ) )

    return transformedData
  } catch ( error ) {
    console.error( 'Error fetching consolidated device data', error )
    throw error
  }
}

export async function getBuildUsers( perPage, page = 1 ) {
  const start = ( page - 1 ) * perPage
  const buildUsers = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.project( process.env.PROJECT_ID ).accounts().list( { perPage, start } )
    }
  )

  return buildUsers
}

export async function getUserByEmail( email ) {
  const { items } = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.project( process.env.PROJECT_ID ).accounts().list( { email } )
    }
  )

  const users = items?.map( user => ( {
    email : user.email,
    name : user.name,
    username : user.username || user.id,
    created : formatTimeStamp( user.created ),
    lastLogin : formatTimeStamp( user.lastLogin ),
    lastVerify : formatTimeStamp( user.lastVerify ),
    userId : user.id
  } ) )

  return users
}

export async function getUserName( projectId, userId ) {
  const item = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.project( projectId ).accounts().get( userId )
    }
  )
  
  return item
}

// boat NMEA actions
export async function deleteNmeaChannels( boatId, channels ) {
  await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      const boat = await api.device( boatId ).get()
      if ( boat ) { 
        const { id, data } = boat
        if ( id ) {
          const dataKeys = Object.keys( data.vessel.nmea )
          const updatedNmea = { ...data.vessel.nmea }

          channels.forEach( ( channel ) => {
            if ( dataKeys.includes( channel ) ) {
              updatedNmea[channel] = null
            }
          } )

          await api.interface( process.env.SYSTEM_ID, 'boat' ).obj( id ).update( {
            data : { ...data, vessel : { ...data.vessel, nmea : updatedNmea } }
          }, {
            resolveOnWritten : { writers : [ 'rt' ], maxWaitTime : 5000 },
            refresh : 'wait_for',
          } )
        } else {
          console.error( 'Selecte device is not available in state' )
        }
      }
    }
  )
}

export async function getMetaData( ) {
  const { metadata } = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.system( process.env.SYSTEM_ID ).get()
    }
  )

  return metadata
}

export async function setOtaFlag( newMetaData ) {
  await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.system( process.env.SYSTEM_ID ).update( { metadata : newMetaData } )
    }
  )
}

export async function getFirmwareVersions( ) {
  const { items } = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      return api.interface( process.env.SYSTEM_ID, 'firmware' ).list()
    }
  )

  return items
}
