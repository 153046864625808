/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */
import { RoutesUtil as Util } from '@leverege/molten/routes'
import { Breadcrumbs, Content, Flex, TableV2, Checkbox, Popup, Toast, Pane } from '@leverege/ui-elements'
import { Item } from '@leverege/ui-elements/lib/esm/Popup'
import { TitleBar, Toolbar } from '@leverege/ui-plugin'
import { GlobalState } from '@leverege/ui-redux'
import { push } from 'connected-react-router'
import React, { useState } from 'react'
import { deleteNmeaChannels } from '../../utils/interface'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import { getNmeaData } from '../../utils/NmeaUtils'

function BoatNMEAScreen( props ) {
  const { match, parentItem } = props
  const { nmea } = parentItem?.data?.data?.vessel || {}
  const networkName = parentItem?.data?.networkId || null
  const name = parentItem?.data?.networkAliases?.[networkName]?.esn || parentItem.data.name
  const boatId = parentItem?.data?.id || null
  const [ selectedChannels, setSelectedChannels ] = useState( [] )
  const [ showConfirmation, setShowConfirmation ] = useState( false )
  
  let nmeaName = 'NMEA'
  if ( process.env.NMEA_PAGE_NAME ) {
    nmeaName = process.env.NMEA_PAGE_NAME
  }

  const mappedData = getNmeaData( nmea )

  const onCheck = ( e ) => {
    const channelNumber = e.data.cell.row.original.channelNumber
    const isAlreadySelected = selectedChannels.includes( channelNumber )

    if ( !isAlreadySelected ) {
      setSelectedChannels( c => [ ...c, channelNumber ] )
    } else {
      setSelectedChannels( c => c.filter( cn => cn !== channelNumber ) )
    }
  }

  const columns = [
    {
      id : 'targeted',
      Header : '',
      width : 52,
      minWidth : 52,
      maxWidth : 52,
      Cell : x => (
        <Checkbox
          variant="small"
          onChange={onCheck}
          eventData={x}
          value={selectedChannels.includes( x.cell.row.original.channelNumber )}/>
      )
    },
    { Header : 'Channel Number', accessor : 'channelNumber' },
    { Header : 'Alias', accessor : 'alias' },
    { Header : 'Type', accessor : 'type' },
    { Header : 'Label', accessor : 'label' },
    { Header : 'Last Update', accessor : 'lastUpdate' },
    { Header : 'Show in App', accessor : 'showInApp' },
    { Header : 'Class Code', accessor : 'classCode' },
    { Header : 'Function Code', accessor : 'functionCode' },
    { Header : 'Manufacturer Code', accessor : 'manufacturerCode' },
    { Header : 'PGNs', accessor : 'pgns' },
  ]

  const rootPath = match ? Util.resolve( '/p/:persona/boat', match ) : ''
  const overViewPath = match ? Util.resolve( '/p/:persona/boat/:boatId/info', match ) : ''

  const onClickBreadcrumb = ( ctx ) => {
    const isRoot = ctx.data?.root
    GlobalState.dispatch( isRoot ? push( rootPath ) : push( overViewPath ) )
  }

  const onDeleteChannels = async () => {
    setShowConfirmation( true )
  }

  const onConfirmDelete = async () => {
    await deleteNmeaChannels( boatId, selectedChannels )
    setSelectedChannels( [] )
    setShowConfirmation( false )
    Toast.success( 'Successfully deleted NMEA chanel' )
  }

  const onDenyDelete = () => {
    setShowConfirmation( false )
  }

  return ( 
    <Content>
      <Pane variant="topNavBreadcrumbs">
        <Breadcrumbs 
          variant="topNavBreadcrumbs"
          onClick={onClickBreadcrumb}
          crumbs={[ 
            { name : process.env.BOAT_BREADCRUMB_NAME, root : true },
            { name, device : true },
            { name : nmeaName }
          ]}
          className="lev-pane lev-sc-pane-mainScreenHeader"/>
      </Pane>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title={nmeaName}
          icon="fa-solid fa-engine">
          { selectedChannels.length > 0 && (
            <Popup
              open
              closeOnEscape
              closeOnDocumentClick
              iconOn="fa-solid fa-ellipsis-vertical"
              iconOff="fa-solid fa-ellipsis-vertical">
              <Popup.Header>
                {`${process.env.CUSTOM_ACTIONS_NAME} - ${nmeaName} Actions`}
              </Popup.Header>
              { selectedChannels.length > 0 && (
                <Item
                  icon="fa-light fa-trash"
                  onClick={onDeleteChannels}>
                  Delete Channels
                </Item>
              ) }
            </Popup>
          ) }
          <Toolbar
            variant="actionBar"
            prefer="icon"
            matchContext={{}}
            context={props} />
        </TitleBar>
      </Content.Header>
      <Content.Area>
        <Flex variant="colM" style={{ height : '100%' }}>
          <TableV2
            variant="dataTable"
            data={mappedData ?? []}
            columns={columns}
            filterable={false}
            sortable={false}/>
        </Flex>
        <ConfirmationDialog
          headerText="Confirm Delete"
          bodyText="Are you sure you want to delete the selected NMEA channels?"
          show={showConfirmation}
          onConfirm={onConfirmDelete}
          onDeny={onDenyDelete}/>
      </Content.Area>
    </Content>
  )
}

export default BoatNMEAScreen
