import { Flex, Toast } from '@leverege/ui-elements'
import React from 'react'
import { useSelector } from 'react-redux'

export default function BoatsFleetGroups() {
  const boatsFleetGroups = useSelector( state => state?.ui?.boatsFleetGroups )
  if ( !boatsFleetGroups ) return <i className="fa-solid fa-spinner-third fa-spin" />

  const onClick = ( v ) => {
    navigator.clipboard.writeText( v )

    Toast.success( 'Fleet Group Id copied to clipboard' )
  }

  const content = boatsFleetGroups.map( b => ( {
    content : b.fleetGroupName,
    fleetGroupId : b.fleetGroupId
  } ) )
  
  return (
    <Flex direction="column">
      {content.length ? content.map( v => (
        <button
          key={v.fleetGroupId}
          style={{ justifyContent : 'flex-end' }}
          className="lev-button lev-sc-button-link lev-sc-button-linkTable"
          type="button"
          onClick={() => onClick( v.fleetGroupId )}>
          {v.content}
        </button>
      ) ) : '-'}
    </Flex>
  )
}
