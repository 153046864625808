import BoatAlertRecipientsLink from './alert-recipents/BoatAlertRecipientsLink'
import BoatAlertRecipientsRoute from './alert-recipents/BoatAlertRecipientsRoute'
import ESNAttribute from './attributes/ESNAttribute'
import FleetAttribute from './attributes/FleetAttribute'
import FleetGroupAttribute from './attributes/FleetGroupAttribute'
import NameAttribute from './attributes/NameAttribute'
import FleetGroupHiddenDataViewer from './attributes/dataViewers/FleetGroupHiddenDataViewer'
import FleetHiddenDataViewer from './attributes/dataViewers/FleetHiddenDataViewer'
import ESNAttributeRenderer from './attributes/renderers/ESNAttributeRenderer'
import FleetAttributeRenderer from './attributes/renderers/FleetAttributeRenderer'
import FleetGroupAttributeRenderer from './attributes/renderers/FleetGroupAttributeRenderer'
import NameAttributeRenderer from './attributes/renderers/NameAttributeRenderer'
import BoatCanBusLink from './can-bus/BoatCanBusLink'
import BoatCanBusRoute from './can-bus/BoatCanBusRoute'
import BoatCommentsLink from './comments/BoatCommentsLink'
import BoatCommentsRoute from './comments/BoatCommentsRoute'
import BoatNMEALink from './nmea/BoatNMEALink'
import BoatNMEARoute from './nmea/BoatNMEARoute'
import DeviceCommandsHistoryLink from './deviceCommands/DeviceCommandsHistoryLink'
import DeviceCommandsHistoryRoute from './deviceCommands/DeviceCommandsHistoryRoute'
import FirmwareHistoryLink from './firmware/FirmwareHistoryLink'
import FirmwareHistoryRoute from './firmware/FirmwareHistoryRoute'
import WirelessSensorCreateHook from './hooks/WirelessSensorCreateHook'
import GlobalStarCreateHook from './hooks/GlobalStarCreateHook'
import EventsLogLink from './eventsLog/EventsLogLink'
import EventsLogRoute from './eventsLog/EventsLogRoute'

const install = ( molten ) => {
  // NMEA
  molten.addPlugin( 'Route', BoatNMEARoute )
  molten.addPlugin( 'Action', BoatNMEALink )
  
  // CAN
  if ( process.env.HAS_BOAT_CAN_PAGE === 'true' ) {
    molten.addPlugin( 'Route', BoatCanBusRoute )
    molten.addPlugin( 'Action', BoatCanBusLink )
  }
  
  // Comments
  molten.addPlugin( 'Route', BoatCommentsRoute )
  molten.addPlugin( 'Action', BoatCommentsLink )
  
  // Alert Recipients 
  molten.addPlugin( 'Route', BoatAlertRecipientsRoute )
  molten.addPlugin( 'Action', BoatAlertRecipientsLink )

  // Boat Index Page
  molten.addPlugin( 'Attribute', ESNAttribute )
  molten.addPlugin( 'Attribute', NameAttribute )
  molten.addPlugin( 'Attribute', FleetAttribute )
  molten.addPlugin( 'Attribute', FleetGroupAttribute )
  molten.addPlugin( 'DataViewer', FleetHiddenDataViewer )
  molten.addPlugin( 'DataViewer', FleetGroupHiddenDataViewer )
  molten.addPlugin( 'AttributeCellRenderer', ESNAttributeRenderer )
  molten.addPlugin( 'AttributeCellRenderer', NameAttributeRenderer )
  molten.addPlugin( 'AttributeValueRenderer', FleetAttributeRenderer )
  molten.addPlugin( 'AttributeValueRenderer', FleetGroupAttributeRenderer )

  // Device Commands
  molten.addPlugin( 'Route', DeviceCommandsHistoryRoute )
  molten.addPlugin( 'Action', DeviceCommandsHistoryLink )
  // Firmware
  molten.addPlugin( 'Route', FirmwareHistoryRoute )
  molten.addPlugin( 'Action', FirmwareHistoryLink )

  // Events Log
  molten.addPlugin( 'Route', EventsLogRoute )
  molten.addPlugin( 'Action', EventsLogLink )

  // Boat sensors ItemCreateHooks
  molten.addPlugin( 'ItemCreateHook', WirelessSensorCreateHook )
  molten.addPlugin( 'ItemCreateHook', GlobalStarCreateHook )
}

export default {
  install,
}
