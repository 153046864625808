/* eslint-disable import/no-extraneous-dependencies */
import { GlobalState } from '@leverege/ui-redux'

export async function getDeviceEvents( deviceId, startDate, endDate, perPage, page = 1 ) {
  const opts = {
    start : startDate,
    end : endDate,
    startIndex : ( page - 1 ) * perPage,
    perPage
  }

  try {
    const data = await GlobalState.dispatch(
      async ( dispatch, getState, { api } ) => {
        return api.device( deviceId ).events().list( opts )
      }
    )

    const events = data.items?.map( ( event ) => {
      const eventDate = new Date( event.time )
      const formattedDate = new Intl.DateTimeFormat( 'en-US', {
        year : 'numeric',
        month : '2-digit',
        day : '2-digit',
        hour : '2-digit',
        minute : '2-digit',
        hour12 : false,
        timeZoneName : 'short'
      } ).format( eventDate )

      return {
        eventName : event.data.eventName,
        eventCode : event.data.eventCode,
        eventType : event.type,
        eventDate : formattedDate,
        id : event.id
      }
    } )

    return events
  } catch ( error ) {
    if ( error.response && error.response.status === 500 ) {
      console.error( 'API returned a 500 error:', error )
      return []
    }

    throw error
  }
}

export default getDeviceEvents
